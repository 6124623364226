import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/member/register',
    name: 'Register',
    component: () => import('../views/member/Register.vue')
  },
  {
    path: '/member/login',
    name: 'Login',
    component: () => import('../views/member/Login.vue')
  },
  {
    path: '/member/edit_password',
    name: 'EditPassword',
    component: () => import('../views/member/EditPassword.vue')
  },
  {
    path: '/income/list',
    name: 'incomeList',
    component: () => import('../views/income/list.vue')
  },
  {
    path: '/withdraw/index',
    name: 'withdraw',
    component: () => import('../views/withdraw/index.vue')
  },
  {
    path: '/withdraw/list',
    name: 'withdrawList',
    component: () => import('../views/withdraw/list.vue')
  },
  {
    path: '/member/bank_info',
    name: 'BankInfo',
    component: () => import('../views/member/BankInfo.vue')
  },
  {
    path: '/member/profile',
    name: 'Profile',
    component: () => import('../views/member/Profile.vue')
  },
  {
    path: '/member/invitation_code',
    name: 'InvitationCode',
    component: () => import('../views/member/InvitationCode.vue')
  },
  {
    path: '/member/gold_partner',
    name: 'GoldPartner',
    component: () => import('../views/member/GoldPartner.vue')
  },
  {
    path: '/promotion/howto',
    name: 'PromotionHowTo',
    component: () => import('../views/promotion/HowTo.vue')
  },
  {
    path: '/promotion/rule',
    name: 'PromotionRule',
    component: () => import('../views/promotion/Rule.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/member/login' || to.path === '/member/register' || to.path === '/member/logout' || to.path === '/member/reset_password') {
    next();
  } else {
    let token = window.sessionStorage.getItem("token");
    if (token == null || token == "") {
      next('/member/login');
    } else {
      next();
    }
  }
});

export default router
