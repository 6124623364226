import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/css/base.css'
import router from './router'
import axios from 'axios'
import qs from 'qs';
Vue.prototype.$qs = qs

axios.defaults.baseURL = 'https://zq.hondamaster.cn'
//axios.defaults.baseURL = 'http://localhost:5010'

Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
